/*===========================================
    base
===========================================*/
html {
  -webkit-font-smoothing: antialiased;
  // overflow-x: hidden;
  overflow: auto;
  @include media-query(lg) {
    font-size: 15px;
  }
  @include media-query(md) {
    //font-size: 1.822vw;
  }
  @include media-query(sm) {
    font-size: 13px;
  }
}

body {
  -webkit-overflow-scrolling: touch;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
  color: $color-text;
  font-family: $font-family-primary;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.03em;
  line-height: 1.8;
  @include media-query(lg) {
    min-width: 1280px;
  }

  // &.is-windows {
  //   font-family: $font-family-secondary;
  // }

  * {
    text-align: justify;
    text-justify: inter-ideograph;
  }

  // p {
  //   margin-bottom: 2em;
  //
  //   &:last-child {
  //     margin-bottom: 0;
  //   }
  // }


  //-----------------------------------------
  // a
  //-----------------------------------------
  a {
    @include transition;
    color: $color-link;
    font-weight: bold;

    &:link {
    }

    &:visited {
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }


  //-----------------------------------------
  // b
  //-----------------------------------------
  b {
    font-weight: bold;
  }


  //-----------------------------------------
  // em
  //-----------------------------------------
  em {
    font-weight: bold;
  }


  //-----------------------------------------
  // strong
  //-----------------------------------------
  strong {
    font-weight: bold;
  }


  //-----------------------------------------
  // mark
  //-----------------------------------------
  mark {
    background-color: $color-mark;
  }


  //-----------------------------------------
  // s
  //-----------------------------------------
  s {
    text-decoration: line-through;
  }


  //-----------------------------------------
  // del
  //-----------------------------------------
  del {
    text-decoration: line-through;
  }


  //-----------------------------------------
  // small
  //-----------------------------------------
  small {
    font-size: 0.8em;
    color: lighten($color-text, 10%);
  }
}

::selection {
  background: $color-mark;
}
