@charset "UTF-8";
/*===========================================
    reset
===========================================*/
html {
  overflow-y: auto;
}

body {
  position: relative;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}

html, body, header, nav, footer, main, aside, section, article, nav, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, caption, mark, dl, dt, dd, ol, ul, li, fieldset, label, legend, option, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  vertical-align: top;
  text-align: left;
}

dl, dt, dd, ol, ul, li {
  list-style: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  *border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  border: none;
  text-align: left;
  vertical-align: top;
}

q, blockquote {
  quotes: none;
}

iframe {
  display: block;
  width: 100%;
  overflow: hidden;
}

img {
  border: none;
  vertical-align: bottom;
}

a {
  border: none;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

sup {
  position: relative;
  bottom: 0.7em;
  font-size: 71.5%;
  vertical-align: baseline;
}

sub {
  position: relative;
  top: 0.1em;
  font-size: 71.5%;
  vertical-align: baseline;
}

abbr, acronym {
  border-bottom: dotted 1px #ccc;
  cursor: help;
}

hr {
  display: none;
}

input[type="submit"], input[type="reset"], input[type="button"], button, select {
  cursor: pointer;
}

/*===========================================
    base
===========================================*/
html {
  -webkit-font-smoothing: antialiased;
  overflow: auto;
}

@media screen and (min-width: 601px), print {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 13px;
  }
}

body {
  -webkit-overflow-scrolling: touch;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
  color: #333;
  font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", sans-serif;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.03em;
  line-height: 1.8;
}

@media screen and (min-width: 601px), print {
  body {
    min-width: 1280px;
  }
}

body * {
  text-align: justify;
  text-justify: inter-ideograph;
}

body a {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #009A98;
  font-weight: bold;
}

body a:hover {
  text-decoration: underline;
}

body a:focus {
  text-decoration: none;
}

body a:active {
  text-decoration: none;
}

body b {
  font-weight: bold;
}

body em {
  font-weight: bold;
}

body strong {
  font-weight: bold;
}

body mark {
  background-color: rgba(102, 102, 102, 0.2);
}

body s {
  text-decoration: line-through;
}

body del {
  text-decoration: line-through;
}

body small {
  font-size: 0.8em;
  color: #4d4d4d;
}

::selection {
  background: rgba(102, 102, 102, 0.2);
}

/*===========================================
    animate
===========================================*/
.is-animated {
  animation-duration: 0.5s;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction:  normal;
  animation-fill-mode: both;
  animation-play-state:  running;
}

.is-animated.fx-infinite {
  animation-iteration-count: infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
